<template>
  <v-row>
    <v-col cols="12">
      <v-card outlined class="d-md-flex flex-md-column" :loading="loading">
        <v-card-text class="text--primary">
          <!-- page header -->
          <div class="d-flex align-center justify-space-between mb-6">
            <h3 class="display-1">Create A New Package</h3>
            <v-btn color="primary" text to="/packages" exact><v-icon left>mdi-arrow-left</v-icon> Back to
              Packages</v-btn>
          </div>
          <!-- page header -->
          <!-- form -->
          <v-form ref="form" @submit.prevent="onSubmit">
            <!-- images -->
            <v-row>
              <v-col cols="12">
                <v-toolbar flat dense>
                  <v-toolbar-title>{{
                    item.images.length ? "Images" : "Add 4 Images Total"
                  }}</v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items v-if="item.images && item.images.length < 4">
                    <v-file-input style="min-width: 400px" accept="image/*" label="Add 4 Images Total"
                      @change="onAddImg($event)" :rules="imageRules"></v-file-input>
                  </v-toolbar-items>
                </v-toolbar>
              </v-col>
              <v-col cols="12" sm="6" v-for="(item, index) in item.images" :key="index">
                <v-card outlined>
                  <v-img :src="item.downloadUrl" cover height="300">
                    <div v-if="item.addOverlayText && item.overlayText"
                      class="text-center title white--text px-4 py-2 text-uppercase" style="
                        background: rgba(0, 0, 0, 0.75);
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                      ">
                      {{ item.overlayText }}
                    </div>
                  </v-img>
                  <v-card-text class="text--primary">
                    <v-row>
                      <v-col cols="12" class="body-1 font-weight-medium">
                        Name:
                        <span>{{ item.name }}</span>
                      </v-col>
                      <!-- add overlay text -->
                      <v-col cols="12">
                        <v-select dense :items="[true, false]" v-model.trim="item.addOverlayText"
                          label="Add Overlay Text *" :rules="[
                            (v) => !!v || v === false || 'Field Required',
                          ]"></v-select>
                      </v-col>
                      <!-- add overlay text -->
                      <!-- overlay text -->
                      <v-col cols="12" v-if="item.addOverlayText">
                        <v-text-field label="Overlay Text *" v-model.trim="item.overlayText" dense
                          :rules="[(v) => !!v || 'Field Required']" counter hint="Max 45 Characters"
                          @keypress="limitCharacterCount($event, 45)" persistent-hint></v-text-field>
                      </v-col>
                      <!-- overlay text -->
                      <!-- download URL -->
                      <v-col cols="12" class="body-1">
                        <span class="d-block mb-2 font-weight-medium">Image URL:</span>
                        <code v-html="item.downloadUrl" />
                      </v-col>
                      <!-- download URL -->
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" depressed @click="onSortImg(index, index - 1)"
                      :class="index == 0 ? 'd-none' : ''">
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn color="primary" depressed @click="onSortImg(index, index + 1)" :class="item.images
                      ? index == item.images.length - 1
                        ? 'd-none'
                        : ''
                      : ''
                      ">
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                    <v-spacer />
                    <v-btn color="error" depressed @click="onDeleteImg(index)">
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-divider class="mt-5 mb-6" />
              </v-col>
            </v-row>
            <!-- images -->
            <v-row>
              <!-- packageCode -->
              <v-col cols="12" sm="6" md="4">
                <v-text-field dense label="Package Code *" v-model.trim="item.packageCode"
                  :rules="[(v) => !!v || 'Field Required']" @input="formatPackageCode($event)"
                  hint="Lowercase, no spaces or dashes (ex: sav123)" persistent-hint :error="packageCodeError"
                  :error-messages="packageCodeErrorMessage" @blur="checkPackageCode"></v-text-field>
              </v-col>
              <!-- package code -->

              <!-- destination -->
              <v-col cols="12" sm="6" md="4">
                <v-text-field label="Destination*" dense v-model.trim="item.destination"
                  :rules="[(v) => !!v || 'Field Required']"></v-text-field>
              </v-col>
              <!-- destination -->

              <!-- roomType -->
              <v-col cols="12" sm="6" md="4">
                <v-select :items="roomTypes" item-text="title" dense item-value="id" return-object
                  v-model.trim="item.roomType" label="Room Type *"
                  :rules="[(v) => v.id !== '' || 'Field Required']"></v-select>
              </v-col>
              <!-- roomType -->

              <!-- numberNights -->
              <v-col cols="12" sm="6" md="4">
                <v-select dense :items="numberNights" item-text="title" item-value="title"
                  v-model.trim="item.numberNights" label="Number of Nights *"
                  :rules="[(v) => !!v || 'Field Required']"></v-select>
              </v-col>
              <!-- numberNights -->

              <!-- cost to org -->
              <v-col cols="12" sm="6" md="4">
                <v-text-field label="Cost to Org. *" dense type="number" v-model.trim="item.costToOrg"
                  @change="(v) => (item.costToOrg = parseInt(v))" @keypress="limitNumberValues($event)"
                  :rules="[(v) => (v || v === 0) || 'Field Required']"></v-text-field>
              </v-col>
              <!-- cost to org -->

              <!-- max retail -->
              <v-col cols="12" sm="6" md="4">
                <v-text-field dense label="Max Retail Value *" type="number"
                  @change="(v) => (item.maxRetail = parseInt(v))" v-model.trim="item.maxRetail"
                  @keypress="limitNumberValues($event)" :rules="[(v) => !!v || 'Field Required']"></v-text-field>
              </v-col>
              <!-- max retail -->

              <!-- package categories -->
              <v-col cols="12" sm="6" md="4">
                <v-select dense multiple :items="packageCategories" item-text="title" item-value="id"
                  v-model.trim="item.packageCategories" label="Package Categories *"
                  :rules="[(v) => !!v.length || 'Field Required']"></v-select>
              </v-col>
              <!-- package categories -->

              <!-- home page sorting priority -->
              <v-col cols="12" sm="6" md="4">
                <v-select dense :items="[0, 1, 2, 3, 4, 5]" v-model.trim="item.homePageSortingPriority"
                  label="Home Page Sorting Priority *" :rules="[(v) => v !== '' || 'Field Required']"></v-select>
              </v-col>
              <!-- home page sorting priority -->

              <!-- terms and conditions -->
              <v-col cols="12" sm="6" md="4">
                <v-select :items="termsAndConditions" dense v-model="item.terms"
                  @change="(v) => (item.termsAndConditions = v.id)" return-object item-text="title" item-value="id"
                  label="Terms &amp; Conditions *" :rules="[(v) => v.id !== '' || 'Field Required']"></v-select>
              </v-col>
              <!-- terms and conditions -->

              <!-- airfare included -->
              <v-col cols="12" sm="6" md="4">
                <v-select dense :items="[true, false]" v-model.trim="item.airfareIncluded" label="Airfare Included *"
                  :rules="[(v) => !!v || v === false || 'Field Required']"></v-select>
              </v-col>
              <!-- airfare included -->

              <!-- all inclusive -->
              <v-col cols="12" sm="6" md="4">
                <v-select :items="[true, false]" v-model.trim="item.allInclusive" dense label="All Inclusive *"
                  :rules="[(v) => !!v || v === false || 'Field Required']"></v-select>
              </v-col>
              <!-- all inclusive -->

              <!-- available -->
              <v-col cols="12" sm="6" md="4">
                <v-select dense :items="[true, false]" v-model.trim="item.available" label="Available *"
                  :rules="[(v) => !!v || v === false || 'Field Required']"></v-select>
              </v-col>
              <!-- available -->

              <!-- add additional info -->
              <v-col cols="12" sm="6" md="4">
                <v-select :items="[true, false]" dense v-model.trim="item.addAdditionalInfo"
                  @change="(v) => (!v ? (item.additionalInfo = '') : '')" label="Add Additional Info *"
                  :rules="[(v) => !!v || v === false || 'Field Required']"></v-select>
              </v-col>
              <!-- add additional info -->

              <!-- youtube video url -->
              <v-col cols="12" sm="6" md="4">
                <v-text-field dense label="YouTube Video URL" v-model.trim="item.youtubeVideoUrl"></v-text-field>
              </v-col>
              <!-- youtube video url -->

              <!-- title -->
              <v-col cols="12" sm="6" md="4">
                <v-text-field label="Package Title *" dense v-model.trim="item.title" counter
                  :rules="[(v) => !!v || 'Field Required']" hint="Max 49 Characters | Wrap content in <p></p>"
                  @keypress="limitCharacterCount($event, 49)" persistent-hint></v-text-field>
              </v-col>
              <!-- title -->

              <!-- subtitle -->
              <v-col cols="12" sm="8">
                <v-text-field dense label="Package Subtitle *" v-model.trim="item.subtitle" counter
                  :rules="[(v) => !!v || 'Field Required']" hint="Max 76 Characters | Wrap content in <p></p>"
                  @keypress="limitCharacterCount($event, 76)" persistent-hint></v-text-field>
              </v-col>
              <!-- subtitle -->

              <!-- accommodations category -->
              <v-col cols="12" md="4">
                <v-select :items="['condo', 'luxury']" v-model.trim="item.accommodationsCategory"
                  label="Accommodations Category *" dense :rules="[(v) => !!v || 'Field Required']"></v-select>
              </v-col>
              <!-- accommodations category -->

              <!-- additional info -->
              <v-col cols="8" v-if="item.addAdditionalInfo">
                <v-textarea v-model="item.additionalInfo" label="Additional Information" persistent-hint
                  hint="Wrap content in <p></p>" rows="8" :rules="[(v) => !!v || 'Field Required']"></v-textarea>
              </v-col>
              <!-- additional info -->
              <!-- inventory properties -->
              <v-col cols="4">
                <v-autocomplete v-model.trim="item.inventoryProperties" dense :items="inventoryPropertiesItems"
                  item-text="text" item-value="resortPropertyId" label="Inventory Properties *" deletable-chips chips
                  no-data-text="No resorts matching that search" small-chips multiple hint="Type in resort name"
                  persistent-hint>
                  <!-- :rules="autocompleteRules" -->
                </v-autocomplete>
              </v-col>
              <v-col cols="4">
                <!-- bedrooms -->
                <v-text-field dense label="Number of Bedrooms *" v-model.trim="item.bedrooms"
                  :rules="[(v) => !!v || 'Field Required']"></v-text-field>
                <!-- bedrooms -->
              </v-col>
              <!-- descriptions -->
              <v-col cols="6">
                <v-textarea v-model="item.description" label="Description" persistent-hint maxlength="200"
                  hint="Description must be within 200 characters" rows="4"></v-textarea>
              </v-col>
              <!-- description -->

              <!-- inventory properties -->

              <!-- <template slot="item" slot-scope="data">
                    <option class="text-capitalize" style="cursor: pointer">
                      {{ data.item.resortName }} - {{ data.item.destination }} -
                      {{ data.item.resortPropertyId }}
                    </option>
                  </template> -->
            </v-row>

            <!-- About This Package -->
            <!-- rich text editor -->
            <v-row>
              <v-col cols="12">
                <v-divider class="mt-5" />
              </v-col>
              <v-col cols="6">
                <v-toolbar-title class="mt-2 mb-2">
                  <strong>About This Package</strong>
                  <span class="ml-6"> </span>
                </v-toolbar-title>
              </v-col>
              <v-col cols="12">
                <quill-editor v-model="item.editorContent" class="custom-quill-editor"></quill-editor>
                <!-- @blur="onEditorBlur" -->
              </v-col>
            </v-row>
            <!-- rich text editor -->
            <!-- Resource Materials -->
            <!-- package PDFs -->
            <v-row>
              <v-col cols="12">
                <v-toolbar flat dense>
                  <v-toolbar-title>Package PDFs</v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items>
                    <!-- Add Button -->
                    <v-btn icon color="primary" @click="addPackagePDF">
                      <v-icon>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
              </v-col>

              <!-- Dynamic Cards for Package PDFs -->
              <v-col cols="12" sm="4" v-for="(pdf, index) in item.packagePDFs" :key="index">
                <v-card outlined>
                  <v-card-text>
                    <v-row>
                      <!-- Radio Buttons for Link/File Selection -->
                      <v-col cols="12">
                        <v-radio-group v-model="pdf.type" :mandatory="true">
                          <v-radio label="Link" value="link"></v-radio>
                          <v-radio label="File" value="file"></v-radio>
                        </v-radio-group>
                      </v-col>

                      <!-- Conditionally Render Fields Based on Radio Selection -->
                      <v-col cols="12" v-if="pdf.type === 'link'">
                        <v-text-field dense label="Link" v-model="pdf.link" :rules="[
                          v => v.startsWith('https://') || 'The link must start with https://'
                        ]"></v-text-field>
                      </v-col>

                      <v-col cols="12" v-if="pdf.type === 'file'">
                        <v-file-input dense label="Upload File" v-model="pdf.file"></v-file-input>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <!-- Delete Button -->
                    <v-btn color="error" depressed @click="onDeletePackagePDFsTab(index)">
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
            <!-- package PDFs -->

            <!-- marketing materials -->
            <v-row>
              <v-col cols="12">
                <v-toolbar flat dense>
                  <v-toolbar-title>Marketing Materials</v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items>
                    <!-- Add Button -->
                    <v-btn icon color="primary" @click="addMarketingmaterials">
                      <v-icon>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
              </v-col>

              <!-- Dynamic Cards for marketing materials -->
              <v-col cols="12" sm="4" v-for="(pdf, index) in item.marketingMaterials" :key="index">
                <v-card outlined>
                  <v-card-text>
                    <v-row>
                      <!-- Radio Buttons for Link/File Selection -->
                      <v-col cols="12">
                        <v-radio-group v-model="pdf.type" :mandatory="true">
                          <v-radio label="Link" value="link"></v-radio>
                          <v-radio label="File" value="file"></v-radio>
                        </v-radio-group>
                      </v-col>

                      <!-- Conditionally Render Fields Based on Radio Selection -->
                      <v-col cols="12" v-if="pdf.type === 'link'">
                        <v-text-field dense label="Link" v-model="pdf.link" :rules="[
                          v => v.startsWith('https://') || 'The link must start with https://'
                        ]"></v-text-field>
                      </v-col>

                      <v-col cols="12" v-if="pdf.type === 'file'">
                        <v-file-input dense label="Upload File" v-model="pdf.file"></v-file-input>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <!-- Delete Button -->
                    <v-btn color="error" depressed @click="onDeleteMarketingMaterialsTab(index)">
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
            <!-- marketing materials -->

            <!-- package Videos -->
            <v-row>
              <v-col cols="12">
                <v-toolbar flat dense>
                  <v-toolbar-title>Package Videos</v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items>
                    <!-- Add Button -->
                    <v-btn icon color="primary" @click="addPackageVideo">
                      <v-icon>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
              </v-col>

              <!-- Dynamic Cards for Package Videos -->
              <v-col cols="12" sm="4" v-for="(video, index) in item.packageVideos" :key="index">
                <v-card outlined>
                  <v-card-text>
                    <v-row>
                      <!-- Radio Buttons for Link/File Selection -->
                      <v-col cols="12">
                        <v-radio-group v-model="video.type" :mandatory="true">
                          <v-radio label="Link" value="link"></v-radio>
                          <v-radio label="File" value="file"></v-radio>
                        </v-radio-group>
                      </v-col>

                      <!-- Conditionally Render Fields Based on Radio Selection -->
                      <v-col cols="12" v-if="video.type === 'link'">
                        <v-text-field dense label="Video Link" v-model="video.link" :rules="[
                          v => v.startsWith('https://') || 'The link must start with https://'
                        ]"></v-text-field>
                      </v-col>

                      <v-col cols="12" v-if="video.type === 'file'">
                        <!-- Ensure only video files can be selected -->
                        <v-file-input dense label="Upload Video" v-model="video.file" accept="video/*"></v-file-input>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <!-- Delete Button -->
                    <v-btn color="error" depressed @click="onDeletePackageVideoTab(index)">
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-divider class="mt-5" />
              </v-col>
            </v-row>
            <!-- package Videos -->

            <!-- Resource Materials -->
            <!-- About This Package -->

            <!--  Upload Video  -->
            <v-row>
              <v-col cols="6">
                <v-toolbar-title class="mt-2 mb-2">
                  <strong>Upload Video</strong>
                  <span class="ml-6"> </span>
                </v-toolbar-title>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-btn color="primary" :style="{ width: `100%` }" @click="$refs.fileVedio.click()" :loading="loading">
                  Upload Video
                  <input ref="fileVedio" type="file" accept=".mp4" style="display: none"
                    @change="onAddVideo($event.target.files)" />
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="item.video">
              <v-col cols="6">
                <iframe width="560" height="315" :src="item.video.downloadUrl" frameborder="0"
                  allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </v-col>
            </v-row>
            <!--  Upload Video  -->

            <!-- extras tab -->
            <v-row>
              <v-col cols="12">
                <v-divider class="mt-5" />
              </v-col>
              <v-col cols="12">
                <v-toolbar flat dense>
                  <v-toolbar-title>Extras Tab</v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items>
                    <v-btn icon color="primary" @click="
                      item.extrasTab.push({
                        title: '',
                        notes: '',
                      })
                      "><v-icon>mdi-plus-circle-outline</v-icon></v-btn>
                  </v-toolbar-items>
                </v-toolbar>
              </v-col>
              <v-col cols="12" sm="4" v-for="(item, index) in item.extrasTab" :key="index">
                <v-card outlined>
                  <v-card-text>
                    <v-row>
                      <!-- extras title -->
                      <v-col cols="12">
                        <v-text-field dense label="Title *" v-model.trim="item.title"
                          :rules="[(v) => !!v || 'Field Required']"></v-text-field>
                      </v-col>
                      <!-- extras title -->

                      <!-- extras notes -->
                      <v-col cols="12">
                        <v-textarea v-model="item.notes" label="Notes" dense :rules="[(v) => !!v || 'Field Required']">
                        </v-textarea>
                        <div>
                          <strong>Formatted Notes:</strong>
                          <v-card class="mx-auto overflow-auto" height="150" outlined>
                            <p v-html="formatNotes(item.notes)" class="pa-2"></p>
                          </v-card>
                        </div>
                      </v-col>
                      <!-- extras notes -->
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn color="error" depressed @click="onDeleteExtrasTab(index)">
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-divider class="mt-3" />
              </v-col>
            </v-row>
            <!-- extras tab -->
            <!-- blurbs -->
            <v-row>
              <v-col cols="12">
                <v-divider class="mt-5" />
              </v-col>
              <v-col cols="12">
                <v-toolbar flat dense>
                  <v-toolbar-title>Blurbs</v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items v-if="item.blurbs.length < 4">
                    <v-btn icon color="primary" @click="
                      item.blurbs.length < 4
                        ? item.blurbs.push({
                          icon: '',
                          title: '',
                          content: '',
                        })
                        : ''
                      "><v-icon>mdi-plus-circle-outline</v-icon></v-btn>
                  </v-toolbar-items>
                </v-toolbar>
              </v-col>
              <v-col cols="12" sm="6" v-for="(item, index) in item.blurbs" :key="index">
                <v-card outlined>
                  <v-card-text>
                    <v-row>
                      <!-- blurb title -->
                      <v-col cols="12">
                        <v-text-field dense label="Blurb Title *" v-model.trim="item.title"
                          :rules="[(v) => !!v || 'Field Required']"
                          hint="Wrap content in <p></p> and Maximum 55 Characters" persistent-hint></v-text-field>
                      </v-col>
                      <!-- blurb title -->
                      <!-- blurb icon -->
                      <v-col cols="12">
                        <v-select item-text="name" item-value="icon" dense :items="sortedBlurbIcons"
                          v-model.trim="item.icon" label="Blurb Icon *" :rules="[
                            (v) => !!v || v === false || 'Field Required',
                          ]">
                          <!-- selection -->
                          <template slot="selection" slot-scope="data">
                            <v-icon color="primary" class="mr-3">{{
                              data.item.icon
                            }}</v-icon>
                            <span class="text-capitalize">{{
                              data.item.name
                            }}</span>
                          </template>
                          <!-- item -->
                          <template slot="item" slot-scope="data">
                            <template>
                              <v-icon class="mr-3" color="primary">{{
                                data.item.icon
                              }}</v-icon>
                              <span class="text-capitalize">{{
                                data.item.name
                              }}</span>
                            </template>
                          </template>
                          <!-- item -->
                        </v-select>
                      </v-col>
                      <!-- blurb icon -->
                      <!-- blurb content -->
                      <v-col cols="12">
                        <v-textarea v-model="item.content" label="Blurb Content" dense persistent-hint
                          hint="Wrap content in <ul><li></li></ul> and Maximum 190 Characters"
                          :rules="[(v) => !!v || 'Field Required']"></v-textarea>
                      </v-col>
                      <!-- blurb content -->
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" depressed @click="onSortBlurb(index, index - 1)"
                      :class="index == 0 ? 'd-none' : ''">
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn color="primary" depressed @click="onSortBlurb(index, index + 1)" :class="item.blurbs
                      ? index == item.blurbs.length - 1
                        ? 'd-none'
                        : ''
                      : ''
                      ">
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                    <v-spacer />
                    <v-btn color="error" depressed v-if="index > 0" @click="onDeleteBlurb(index)">
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-divider class="mt-3" />
              </v-col>
            </v-row>
            <!-- blurbs -->
            <!-- Alternate Image -->
            <v-row>
              <v-col cols="6">
                <v-toolbar-title class="mt-2 mb-2">
                  <strong>Alternate Images</strong>
                  <span class="ml-6"> </span>
                </v-toolbar-title>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-btn color="primary" :style="{ width: `100%` }" @click="$refs.fileInput.click()" :loading="loading">
                  Upload images
                  <input ref="fileInput" type="file" accept=".jpg, .png, .gif" style="display: none" multiple
                    @change="onAlternateAddImg($event.target.files)" />
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" v-for="(item, index) in item.altImages" :key="index">
                <v-card outlined>
                  <v-img :src="item.downloadUrl" cover height="300">
                    <!-- <div
                        v-if="item.addOverlayText && item.overlayText"
                        class="text-center title white--text px-4 py-2 text-uppercase"
                        style="
                          background: rgba(0, 0, 0, 0.75);
                          position: absolute;
                          bottom: 0;
                          left: 0;
                          width: 100%;
                        "
                      >
                        {{ item.overlayText }}
                      </div> -->
                  </v-img>
                  <v-card-text class="text--primary">
                    <v-row>
                      <v-col cols="12" class="body-1 font-weight-medium">
                        Name:
                        <span>{{ item.name }}</span>
                      </v-col>
                      <!-- add overlay text -->
                      <v-col cols="12">
                        <v-select dense :items="[true, false]" v-model.trim="item.addOverlayText"
                          label="Add Overlay Text *" :rules="[
                            (v) => !!v || v === false || 'Field Required',
                          ]"></v-select>
                      </v-col>
                      <!-- add overlay text -->
                      <!-- overlay text -->
                      <v-col cols="12" v-if="item.addOverlayText">
                        <v-text-field label="Overlay Text *" v-model.trim="item.overlayText" dense
                          :rules="[(v) => !!v || 'Field Required']" counter hint="Max 45 Characters"
                          @keypress="limitCharacterCount($event, 45)" persistent-hint></v-text-field>
                      </v-col>
                      <!-- overlay text -->
                      <!-- download URL -->
                      <v-col cols="12" class="body-1">
                        <span class="d-block mb-2 font-weight-medium">Image URL:</span>
                        <code v-html="item.downloadUrl" />
                      </v-col>
                      <!-- download URL -->
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" depressed @click="onSortAltImg(index, index - 1)"
                      :class="index == 0 ? 'd-none' : ''">
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-btn color="primary" depressed @click="onSortAltImg(index, index + 1)" :class="item.altImages
                      ? index == item.altImages.length - 1
                        ? 'd-none'
                        : ''
                      : ''
                      ">
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                    <v-spacer />
                    <v-btn color="error" depressed @click="onDeleteAltImg(index)">
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>

              <v-col cols="12">
                <v-divider class="mt-5 mb-6" />
              </v-col>
            </v-row>
            <!-- Alternate Image -->
          </v-form>
          <!-- form -->
        </v-card-text>
        <v-card-actions class="sticky-v-card-actions">
          <v-spacer />
          <!-- submit button -->
          <v-btn color="primary" depressed @click="onSubmit" :loading="loading" :disabled="loading">
            Create Package</v-btn>
          <!-- submit button -->
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import { mainApp, portalsApp } from "@/firebase";
const packages = mainApp.firestore().collection("packages");
const roomTypes = mainApp.firestore().collection("roomTypes");
const numberNights = mainApp.firestore().collection("numberNights");
const packageCategories = mainApp.firestore().collection("packageCategories");
const termsAndConditions = mainApp.firestore().collection("termsAndConditions");
const inventoryProperties = portalsApp
  .firestore()
  .collection("inventoryProperties");
export default {
  firestore: {
    roomTypes,
    numberNights,
    packageCategories,
    termsAndConditions,
    inventoryProperties,
    packages,
  },
  data: () => ({
    packageCodeError: false,
    packageCodeErrorMessage: "",
    roomTypes: [],
    numberNights: [],
    packageCategories: [],
    termsAndConditions: [],
    inventoryProperties: [],
    packages: [],
    item: {
      accommodationsCategory: "",
      addAdditionalInfo: "",
      additionalInfo: "",
      airfareIncluded: "",
      allInclusive: "",
      adminNotes: "",
      available: "",
      extrasTab: [
        {
          title: "",
          notes: "",
        },
      ],
      blurbs: [
        {
          icon: "",
          title: "",
          content: "",
        },
      ],
      costToOrg: "",
      dateCreated: new Date().toISOString().substring(0, 10),
      dateUpdated: "",
      destination: "",
      description: "",
      homePageSortingPriority: "",
      id: "",
      images: [],
      altImages: [],
      inventoryProperties: [],
      maxRetail: "",
      numberNights: "",
      packageCategories: [],
      packageCode: "",
      pdfDownloadUrl: "",
      roomType: {
        id: "",
        title: "",
      },
      socialSharingImage: "",
      subtitle: "",
      terms: {
        content: "",
        id: "",
        title: "",
      },
      termsAndConditions: "",
      title: "",
      youtubeVideoUrl: "",
      video: "",
      editorContent: "",
      packagePDFs: [{
        type: '',
        link: '',
        file: '',
        name: '',
      }],
      marketingMaterials: [{
        type: '',
        link: '',
        file: '',
        name: '',
      }],
      packageVideos: [{
        type: '',
        link: '',
        file: '',
        name: '',
      }],
    },
  }),
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    inventoryPropertiesItems() {
      return this.inventoryProperties.map((i) => {
        let item = {
          resortPropertyId: i.resortPropertyId,
          text:
            i.resortName + " - " + i.destination + " - " + i.resortPropertyId,
        };
        return item;
      });
    },
    imageRules() {
      if (!this.item.images.length) {
        return [false];
      } else {
        if (this.item.images.length < 4) {
          return [false];
        }
      }
    },
    autocompleteRules() {
      if (
        !this.item.inventoryProperties ||
        !this.item.inventoryProperties.length
      ) {
        return [false];
      } else {
        return [true];
      }
    },
    sortedBlurbIcons() {
      const blurbIcons = [
        {
          name: "bullet",
          icon: "mdi-circle-small",
        },
        {
          name: "information",
          icon: "mdi-information",
        },
        {
          name: "star",
          icon: "mdi-star",
        },
        {
          name: "city",
          icon: "mdi-city-variant",
        },
        {
          name: "airplane",
          icon: "mdi-airplane",
        },
        {
          name: "award",
          icon: "mdi-seal-variant",
        },
        {
          name: "food and drink",
          icon: "mdi-food-fork-drink",
        },
        {
          name: "music",
          icon: "mdi-music",
        },
        {
          name: "music circle",
          icon: "mdi-music-circle-outline",
        },
        {
          name: "museum",
          icon: "mdi-bank",
        },
        {
          name: "smile",
          icon: "mdi-emoticon-happy",
        },
        {
          name: "thumbs up",
          icon: "mdi-thumb-up",
        },
        {
          name: "pool",
          icon: "mdi-pool",
        },
        {
          name: "swim",
          icon: "mdi-swim",
        },
        {
          name: "snorkel",
          icon: "mdi-diving-snorkel",
        },
        {
          name: "scuba",
          icon: "mdi-diving-scuba-tank",
        },
        {
          name: "beach",
          icon: "mdi-beach",
        },
        {
          name: "lifeguard",
          icon: "mdi-tower-beach",
        },
        {
          name: "pets",
          icon: "mdi-paw",
        },
        {
          name: "fish",
          icon: "mdi-fish",
        },
        {
          name: "grill",
          icon: "mdi-grill",
        },
        {
          name: "bar",
          icon: "mdi-glass-mug-variant",
        },
        {
          name: "binoculars",
          icon: "mdi-binoculars",
        },
        {
          name: "bed",
          icon: "mdi-bed",
        },
        {
          name: "flower",
          icon: "mdi-flower-tulip",
        },
        {
          name: "pizza",
          icon: "mdi-pizza",
        },
        {
          name: "lake",
          icon: "mdi-waves",
        },
        {
          name: "mountain",
          icon: "mdi-terrain",
        },
        {
          name: "water ski",
          icon: "mdi-ski-water",
        },
        {
          name: "ski",
          icon: "mdi-ski",
        },
        {
          name: "snow",
          icon: "mdi-snowflake",
        },
        {
          name: "sun",
          icon: "mdi-weather-sunny",
        },
        {
          name: "forest",
          icon: "mdi-pine-tree",
        },
        {
          name: "castle",
          icon: "mdi-castle",
        },
        {
          name: "golf",
          icon: "mdi-golf",
        },
        {
          name: "car",
          icon: "mdi-car",
        },
        {
          name: "convertible",
          icon: "mdi-car-convertible",
        },
      ];
      return blurbIcons.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0
      );
    },
  },
  methods: {
    formatNotes(notes) {
      const urlRegex = /((https?|ftp):\/\/[^\s/$.?#].[^\s]*)/g;
      const text = notes.replace(/\n/g, "\n <br>");
      return text.replace(urlRegex, function (url) {
        if (url.match(/^https?:\/\//)) {
          return `<a href="${url}" target="_blank">${url}</a>`;
        } else {
          return url;
        }
      });
    },

    async checkPackageCode() {
      // checks to see if package code already exists in DB
      if (!this.item.packageCode) return;
      this.packageCodeError = false;
      this.packageCodeErrorMessage = "";
      let docRef = mainApp
        .firestore()
        .collection("packages")
        .doc(this.item.packageCode.toLowerCase());
      let doc = await docRef.get();
      if (doc.exists) {
        this.packageCodeError = true;
        this.packageCodeErrorMessage = "Package Code Already Exists";
        await this.$store.dispatch("setSnackbar", {
          display: true,
          color: "error",
          text: "Package code already exists",
          timeout: 10000,
        });
      }
    },
    async onSubmit() {
      // console.log("packagePDFs", this.item.packagePDFs);
      // console.log("marketingMaterials", this.item.marketingMaterials);
      // console.log("packageVideos", this.item.packageVideos);
      // form validation

      if (!this.$refs.form.validate()) {
        await this.$store.dispatch("setLoading", false);
        await this.$store.dispatch("setSnackbar", {
          display: true,
          color: "error",
          text: "Please check form for errors",
          timeout: 10000,
        });
        return;
      }
      // if (!this.item.altImages || this.item.altImages.length < 2) {
      //   await this.$store.dispatch("setLoading", false);
      //   await this.$store.dispatch("setSnackbar", {
      //     display: true,
      //     color: "error",
      //     text: "Please upload atleast 2 Alternative Image",
      //     timeout: 10000,
      //   });
      //   return;
      // }

      // proceed with package creation
      await this.$store.dispatch("setLoading", true);
      // format data
      this.item.bedrooms = parseInt(this.item.bedrooms);

      // upload images & get downloadURL
      let i;
      for (i = 0; i < this.item.images.length; i++) {
        let image = this.item.images[i];
        let imageRef = mainApp
          .storage()
          .ref(`packages/${this.item.packageCode}/${image.name}`);
        let imageUploaded = await imageRef.put(image.file);
        image.downloadUrl = await imageUploaded.ref.getDownloadURL();
        delete image.file;
      }

      // upload alternative images & get downloadURL
      if (this.item.altImages && this.item.altImages.length) {
        let j;
        for (j = 0; j < this.item.altImages.length; j++) {
          let image = this.item.altImages[j];
          let imageRef = mainApp
            .storage()
            .ref(`packages/${this.item.packageCode}/${image.name}`);
          let imageUploaded = await imageRef.put(image.file);
          image.downloadUrl = await imageUploaded.ref.getDownloadURL();
          delete image.file;
        }
      }

      // upload video
      if (this.item.video) {
        let video = this.item.video;
        let videoRef = mainApp
          .storage()
          .ref(`packageVideos/${this.item.packageCode}/${video.name}`);
        let videoUploaded = await videoRef.put(video.file);
        video.downloadUrl = await videoUploaded.ref.getDownloadURL();
        delete video.file;
      }

      // upload package pdfs
      if (this.item.packagePDFs) {
        for (let i = 0; i < this.item.packagePDFs.length; i++) {
          if (this.item.packagePDFs[i].file) {
            let pdf = this.item.packagePDFs[i];
            let pdfRef = mainApp.storage().ref(`pdfs/${pdf.file.name}`);
            let pdfUploaded = await pdfRef.put(pdf.file);
            pdf.downloadUrl = await pdfUploaded.ref.getDownloadURL();
            console.log("pdf.downloadUrl", pdf.downloadUrl);
            pdf.link = "";
            pdf.name = pdf.file.name;
            delete pdf.file;
          }
          else {
            this.item.packagePDFs[i].name = "";
            this.item.packagePDFs[i].downloadUrl = "";
            delete this.item.packagePDFs[i].file;
          }
        }
      }

      // upload marketing materials
      if (this.item.marketingMaterials) {
        for (let i = 0; i < this.item.marketingMaterials.length; i++) {
          if (this.item.marketingMaterials[i].file) {
            let doc = this.item.marketingMaterials[i];
            let docRef = mainApp.storage().ref(`marketingMaterials/${doc.file.name}`);
            let docUploaded = await docRef.put(doc.file);
            doc.downloadUrl = await docUploaded.ref.getDownloadURL();
            console.log("doc.downloadUrl", doc.downloadUrl);
            doc.link = "";
            doc.name = doc.file.name;
            delete doc.file;
          }
          else {
            this.item.marketingMaterials[i].name = "";
            this.item.marketingMaterials[i].downloadUrl = "";
            delete this.item.marketingMaterials[i].file;
          }
        }
      }

      // upload package videos
      if (this.item.packageVideos) {
        for (let i = 0; i < this.item.packageVideos.length; i++) {
          if (this.item.packageVideos[i].file) {
            let video = this.item.packageVideos[i];
            let videoRef = mainApp
              .storage()
              .ref(`packageVideos/${this.item.packageCode}/${video.file.name}`);
            let videoUploaded = await videoRef.put(video.file);
            video.downloadUrl = await videoUploaded.ref.getDownloadURL();
            console.log("video.downloadUrl", video.downloadUrl);
            video.link = "";
            video.name = video.file.name;
            delete video.file;
          }
          else {
            this.item.packageVideos[i].name = "";
            this.item.packageVideos[i].downloadUrl = "";
            delete this.item.packageVideos[i].file;
          }
        }
      }

      // create firestore doc
      let packageDocRef = mainApp
        .firestore()
        .collection("packages")
        .doc(this.item.packageCode);

      // this.item.id = this.item.packageCode
      // console.log("packageDocRef will be set", this.item);
      await packageDocRef.set(this.item);
      // console.log("packageDocRef after await");

      // update package PDF && local item
      // let pdfResponse = await axios.post(
      //   "https://savrestapi.com/savadmin/create-package-pdf",
      //   this.item
      // );
      // this.item.pdfDownloadUrl = pdfResponse.data.pdfDownloadUrl;

      // // update firestore package doc
      // await packageDocRef.update({
      //   pdfDownloadUrl: pdfResponse.data.pdfDownloadUrl,
      // });

      // update package guide PDF
      let packageGuidePackages = [];
      let item;
      for (item = 0; item < this.packages.length; item++) {
        let packageToUpdate = this.packages[item];
        packageToUpdate.costToOrg = "$" + packageToUpdate.costToOrg + ".00";
        packageToUpdate.maxRetail = "$" + packageToUpdate.maxRetail + ".00";
        packageGuidePackages.push(packageToUpdate);
        if (this.packages.length == packageGuidePackages.length) {
          // await axios.post(
          //   "https://savrestapi.com/savadmin/create-package-guide-pdf",
          //   {
          //     packages: packageGuidePackages,
          //   }
          // );

          // create package in portalsApp
          let portalsAppPackageDocRef = portalsApp
            .firestore()
            .collection("retailPackages")
            .doc(this.item.packageCode);
          await portalsAppPackageDocRef.set(this.item);

          await this.$store.dispatch("setLoading", false);
          await this.$store.dispatch("setSnackbar", {
            display: true,
            color: "success",
            text: "Package created successfully",
            timeout: 5000,
          });
          console.log("Package created successfully.");
          this.$router.push("/packages");
        }
      }

      await this.$store.dispatch("setLoading", false);
      return;
    },
    formatPackageCode(value) {
      this.item.packageCode = value.toLowerCase().replace(" ", "");
    },
    onSortBlurb(from, to) {
      this.item.blurbs.splice(to, 0, this.item.blurbs.splice(from, 1)[0]);
    },
    onDeleteExtrasTab(index) {
      this.item.extrasTab.splice(index, 1);
    },
    onDeleteBlurb(index) {
      this.item.blurbs.splice(index, 1);
    },
    onSortImg(from, to) {
      this.item.images.splice(to, 0, this.item.images.splice(from, 1)[0]);
    },
    onSortAltImg(from, to) {
      this.item.altImages.splice(to, 0, this.item.altImages.splice(from, 1)[0]);
    },
    onDeleteImg(index) {
      this.item.images.splice(index, 1);
    },
    onDeleteAltImg(index) {
      this.item.altImages.splice(index, 1);
    },
    onAddVideo(files) {
      if (files) {
        console.log("Video", files);
        let file = files[0];
        let video = {
          file: file,
          name: file.name.replace(" ", "-"),
          downloadUrl: URL.createObjectURL(file),
          dateSubmitted: new Date().toISOString(),
        };
        this.item.video = video;
      }
    },
    onAlternateAddImg(files) {
      if (files) {
        // console.log(files);
        for (let i = 0; i < files.length; i++) {
          let file = files[i];
          let img = {
            file: file,
            name: file.name.replace(" ", "-"),
            downloadUrl: URL.createObjectURL(file),
            addOverlayText: false,
            overlayText: "",
            dateSubmitted: new Date().toISOString(),
          };
          this.item.altImages.push(img);
        }
      }
    },
    onAddImg(file) {
      if (file && this.item.images.length < 4) {
        let img = {
          file: file,
          name: file.name.replace(" ", "-"),
          downloadUrl: URL.createObjectURL(file),
          addOverlayText: false,
          overlayText: "",
          dateSubmitted: new Date().toISOString(),
        };
        this.item.images.push(img);
      }
    },
    // onEditorBlur(event) {
    //   console.log("editorContent", this.item.editorContent);
    // },
    addPackagePDF() {
      this.item.packagePDFs.push({
        type: '',
        link: '',
        file: '',
        name: '',
        downloadUrl: '',
      });
    },
    onDeletePackagePDFsTab(index) {
      this.item.packagePDFs.splice(index, 1);
    },
    addMarketingmaterials() {
      this.item.marketingMaterials.push(
        {
          type: '',
          link: '',
          file: '',
          name: '',
          downloadUrl: '',
        }
      );
    },
    onDeleteMarketingMaterialsTab(index) {
      this.item.marketingMaterials.splice(index, 1);
    },
    addPackageVideo() {
      this.item.packageVideos.push({
        type: '',
        link: '',
        file: '',
        name: '',
        downloadUrl: '',
      });
    },
    onDeletePackageVideoTab(index) {
      this.item.packageVideos.splice(index, 1);
    },
  },
};
</script>

<style lang="css" scoped>
/* Custom styles to fix the height of the Quill Editor */
.custom-quill-editor>>>.ql-container {
  height: 300px;
  max-height: 300px;
  overflow-y: auto;
}

.custom-quill-editor>>>.ql-editor {
  height: 100%;
}
</style>